import axios from "@/plugins/axios.js"

const actions = {
  fetchReviews({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/reviews?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
              commit('SET_REVIEWS', response.data.data)
            }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchReview({ commit }, reviewId) {
    return new Promise((resolve, reject) => {
      axios.get(`/reviews/${reviewId}/show`)
        .then((response) => {
          if(response.data.success){
              commit('SET_REVIEW', response.data.data)
            }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}

const getters = {

}


const mutations = {
 ADD_REVIEW(state, item) {
   state.pageData.data.unshift(item)
 },
 SET_REVIEWS(state, data) {
   state.pageData = data
 },
 UPDATE_REVIEW(state, item){
   state.review = item
   if(state.pageData.data.length){
       const dataIndex = state.pageData.data.findIndex((g) => g.id == item.id);
       Object.assign(state.pageData.data[dataIndex], item);
     }
 },
 DELETE_REVIEW(state, itemId){
   const itemIndex = state.pageData.data.findIndex((g) => g.id == itemId)
   state.pageData.data.splice(itemIndex, 1)
 },
}

const state = {
  pageData: {
    data: []
  },
  review: {},
}

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

