export default{
    methods:{
        absoluteUrl(path = ""){
            return this.$store.state.domain+path
        },
        calculateDeliveryTime(time){
            if(!time){return '0 day'}
            return (time > 24) ? Math.round(time/24) + ' days' : '1 day'
        },
        isImageFile(ext){
            return  ['png','jpeg','svg','jpg','webp'].includes(ext ? ext.toLowerCase() : null)
        },
        preText(content) {
            if(!content) return;
            const words = content.replace(/\n/g, ' <br>').split(' ');
            const formattedWords = [];
            words.forEach(word => {
              if (word.startsWith('http://') || word.startsWith('https://')  || word.startsWith('www.')) {
                formattedWords.push(`<a href="${word}" class="text-primary" target="_blank"> ${word} </a>`);
              } else {
                formattedWords.push(word)
              }
            });
            return formattedWords.join(' ')
        },
        statusBadge(status){
          if(!status) return;
          let codes = { active : '#0ec569', reviewed : '#41d051', paid: '#0ec569',  not_paid : '#000000',
                    draft: '#222222', pending: '#EA5455', delivered: '#0ec569', completed: '#008000', canceled: '#FF0000', refunded: '#FF0000',
          }
          return `<span style="background:${codes[status.toLowerCase()]}24; color:${codes[status]}; padding:0px 5px 2px 5px;line-height:1; border-radius:4px;">${status.replace(/_/g, ' ')}</span>`
        },
        scrollToView(elID){
            if(!elID){return}
            const yOffset = -200;
            const element = document.getElementById(elID);
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        },
        alertSuccess(text){
            this.$store.dispatch("alertSuccess", text)
        },
        alertError(text){
            this.$store.dispatch("alertError", text)
        },
        confirmDelete(option){
            return new Promise((resolve, reject) => {
              Swal.fire({
                title: option.title || "Are you sure?",
                text: option.text,
                reverseButtons: true,
                showCancelButton: true,
                confirmButtonColor: "#f1556c",
                cancelButtonColor: "#111",
                confirmButtonText: "Delete it!",
              }).then((result) => {
                resolve(result)
              }).catch((error) => { reject(error) });
            })
        }
    },
}
