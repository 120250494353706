export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '/main',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: () => import('@views/Dashboard.vue'),
                meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      active: true
                    }
                  ],
                  title: 'Dashboard',
                  authRequired: true,
                }
            },
            {
                path: '/account',
                name: 'account',
                component: () => import('@views/account/UserEdit.vue'),
                meta: {
                   breadcrumb: [
                      {
                        text: "Dashboard",
                        to:{ path:"/"}
                      },
                      {
                        text: "Account",
                        active: true
                      }
                  ],
                  title: 'Account',
                  authRequired: true,
                },
            },
            {
              path: '/categories',
              name: 'categories',
              component: () => import('@views/categories/CategoryList.vue'),
              meta: {
                breadcrumb: [
                  {
                    text: "Dashboard",
                    to: { path:"/"}
                  },
                  {
                    text: "categories",
                    active: true
                  }
                ],
                title: "Categories",
                authRequired: true,
              },
            },
            {
              path: '/products',
              name: 'products',
              component: () => import('@views/products/ProductList.vue'),
              meta: {
                breadcrumb: [
                  {
                    text: "Dashboard",
                    to: { path:"/"}
                  },
                  {
                    text: "Products",
                    active: true
                  }
                ],
                title: "Products",
                authRequired: true,
              },
            },
            {
              path: '/products/:productId(\\d+)',
              name: 'product-show',
              component: () => import('@views/products/ProductShow.vue'),
              meta: {
                breadcrumb: [
                    {
                      text: "Dashboard",
                      to: { path:"/"}
                    },
                    {
                      text: "products",
                      to: { path:"/products"}
                    },
                    {
                      text: "View Product",
                      active: true
                    }
                ],
                title: "View product",
                authRequired: true,
              },
            },
            {
              path: '/orders',
              name: 'orders',
              component: () => import('@views/orders/OrderList.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      to:{ path:"/"}
                    },
                    {
                      text: "Orders",
                      active: true
                    }
                ],
                title: "Orders",
                authRequired: true,
              }
            },
            {
              path: '/orders/:orderId(\\d+)',
              name: 'order-show',
              component: () => import('@views/orders/OrderShow.vue'),
              meta: {
                title: "View Order",
                authRequired: true,
              },
            },
            {
                path: '/users',
                name: 'users-list',
                component: () => import('@views/users/UserList.vue'),
                meta: {
                  breadcrumb: [
                      {
                        text: "Dashboard",
                        to: { path:"/"}
                      },
                      {
                        text: "User List",
                        active: true
                      }
                  ],
                  title: "Users List",
                  authRequired: true,
                },
            },
            {
                path: '/users/create',
                name: 'users-create',
                component: () => import('@views/users/UserCreate.vue'),
                meta: {
                  breadcrumb: [
                      {
                        text: "Dashboard",
                        to: { path:"/"}
                      },
                      {
                        text: "User",
                        active: true
                      }
                  ],
                  title: "Create User",
                  authRequired: true,
                },
            },
            {
              path: '/users/:userId(\\d+)',
              name: 'user-view',
              redirect: to => {
                return { path: `/users/${to.params.userId}/edit` }
              },
              meta: {
                title: "Vew User",
                authRequired: true,
              },
            },
            {
                path: '/users/:userId(\\d+)/edit',
                name: 'user-edit',
                component: () => import('@views/users/edit/UserEdit.vue'),
                meta: {
                  breadcrumb: [
                      {
                        text: "Dashboard",
                        to: { path:"/"}
                      },
                      {
                        text: "Users",
                        to: { path:"/users"}
                      },
                      {
                        text: "Edit User",
                        active: true
                      }
                  ],
                  title: "Vew User",
                  authRequired: true,
                },
            },
            {
              path: '/reviews',
              name: 'reviews',
              component: () => import('@views/reviews/ReviewList.vue'),
              meta: {
                  breadcrumb: [
                  {
                    text: "Dashboard",
                    to: { path:"/"}
                  },
                  {
                    text: "Reviews",
                    active: true
                  }
                ],
                title: 'Reviews',
                authRequired: true,
              }
            },
            {
              path: '/payments',
              name: 'payments',
              component: () => import('@views/payments/Payments.vue'),
              meta: {
                  breadcrumb: [
                    {
                      text: "Dashboard",
                      to: { path:"/"}
                    },
                    {
                      text: "Payments List",
                      active: true
                    }
                ],
                title: "Payments",
                authRequired: true,
              }
            },
            {
                path: '/activity',
                name: 'activity',
                component: () => import('@views/activity/ActivityList.vue'),
                meta: {
                    breadcrumb: [
                      {
                        text: "Dashboard",
                        to:{ path:"/"}
                      },
                      {
                        text: "Activities",
                        active: true
                      }
                  ],
                  title: "Activities",
                  authRequired: true,
                }
            },
            {
              path: '/settings/coupons',
              name: 'coupons',
              component: () => import('@views/settings/CouponList.vue'),
              meta: {
                breadcrumb: [
                  {
                    text: "Dashboard",
                    to:{ path:"/"}
                  },
                  {
                    text: "Coupons",
                    active: true
                  }
                ],
                title: "Coupons",
                authRequired: true,
              }
            },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
            path: '/login',
            name: 'login',
            component: () => import('@/views/pages/Login.vue'),
            meta: {
              guestRequired: true,
              title: "Login"
            }
          },
          {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/pages/Error404.vue'),
            meta: {
              title: "404 - Page not Found"
            }
          },
          {
            path: '/error-500',
            name: 'error-500',
            component: () => import('@/views/pages/Error500.vue'),
            meta: {
              title: "500 - Server error"
            }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]