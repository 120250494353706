const options = {
  truncate(value, limit) {
    if(!value) return;
    if(value.length > limit){
      return value.substring(0, limit)+' ...'
    }else{
      return value
    }
  },
  truncate_start(value, limit) {
    if(!value) return;
    if(value.length > limit){
      return '...'+value.substring(value.length, value.length - limit)
    }else{
      return value
    }
  },
  capitalize (value) {
    if (!value) return value
    value = value.toString()
    let arr = value.split(" ")
    let capitalized_array = []
    arr.forEach((word) => {
      let capitalized = word.charAt(0).toUpperCase() + word.slice(1)
      capitalized_array.push(capitalized)
    })
    return capitalized_array.join(" ");
  },
  time(value, is24HrFormat = false) {
    if(value) {
      const date = new Date(Date.parse(value));
      let hours = date.getHours();
      const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
      if(!is24HrFormat) {
        const time = hours > 12 ? 'AM' : 'PM';
        hours = hours % 12 || 12;
        return hours + ':' + min + ' ' + time
      }
      return hours + ':' + min
    }
  },
  date_time(value, index = 0) {
    var options = [
      { weekday:'long', year:'numeric', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
      { year:'2-digit', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
      { year:'numeric', month:'short', day:'2-digit' }
    ];
    var givenDate  = new Date(value);
    return new Intl.DateTimeFormat('en-US', options[index]).format(givenDate)
  },
  date(value, fullDate = false) {
    var options = {year: 'numeric', month: 'long', day: 'numeric' };
    var givenDate  = new Date(value);
    if(!value || givenDate == 'Invalid Date'){return;}
    if(!fullDate) return givenDate.toLocaleDateString("en-US", options);
    else return givenDate.toLocaleString("en-US");
  },
  month_year(value) {
    var options = {month: 'short', year: 'numeric'};
    var givenDate  = new Date(value);
    if(!value || givenDate == 'Invalid Date'){return;}
    return givenDate.toLocaleString("en-US", options);
  },
  month(val, showYear = true) {
    val = String(val)
    const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
    if(!showYear) {
      return regx.exec(val)[1];
    }else {
      return regx.exec(val)[1] + ' ' + regx.exec(val)[2];
    }
  },
  csv(value) {
    return value.join(', ')
  },
  filter_tags(value) {
    return value.replace(/<\/?[^>]+(>|$)/g, "")
  },
  k_formatter(num) {
    return num > 999 ? (num/1000).toFixed(1) + 'k' : num
  },
  money_format(number) {
    let i = parseFloat(number); if(isNaN(i)) {return;}
    return i.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  },
  deliveryDate(startDate, hrs){
    if(!startDate || !hrs) return;
    let option = { year:'2-digit', month:'short', day:'2-digit'}
    var givenDate  = new Date().setTime(new Date(startDate).getTime() + (parseInt(hrs)*60*60*1000));
    return new Intl.DateTimeFormat('en-US', option).format(givenDate)
  },
  patchUpdatables(data, filter) {
    let allowed = Object.keys(data);
    const filteredData =  Object.keys(filter || {})
      .filter(key => allowed.includes(key))
      .reduce((obj, key) => {
          obj[key] = filter[key];
          return obj;
      }, {});
    return JSON.parse(JSON.stringify(filteredData))
  },
  date_ago(utctime, index = 0) {
    const iptDate = new Date(utctime);
    const curDate = new Date();
    const timeD = curDate - iptDate;
    const millisecsPerSec = 1000;
    const millisecsPerMin = 60 * millisecsPerSec;
    const millisecsPerHr = 60 * millisecsPerMin;
    const millisecsPerDay = 24 * millisecsPerHr;

    if (timeD < millisecsPerDay) {
      const daysAgo = Math.floor(timeD / millisecsPerDay);
      const hrsAgo = Math.floor((timeD % millisecsPerDay) / millisecsPerHr);
      const minsAgo = Math.floor((timeD % millisecsPerHr) / millisecsPerMin);
      const secsAgo = Math.floor((timeD % millisecsPerMin) / millisecsPerSec);

      if (daysAgo > 0) {
        return `${daysAgo} day${daysAgo > 1 ? 's' : ''} ago`;
      } else if (hrsAgo > 0) {
        return `${hrsAgo} hr${hrsAgo > 1 ? 's' : ''} ago`;
      } else if (minsAgo > 0) {
        return `${minsAgo} min${minsAgo > 1 ? 's' : ''} ago`;
      } else if (secsAgo > 0) {
        return `${secsAgo} sec${secsAgo > 1 ? 's' : ''} ago`;
      } else {
        return "just now";
      }
    } else {
      const options = [
        { year:'2-digit', month:'short', day:'2-digit', hour: '2-digit', minute:'2-digit' },
        { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' }
      ];
      return new Intl.DateTimeFormat('en-US', options[index]).format(iptDate)
    }
  }
}

export default {
  install: (app) => {
    app.config.globalProperties.$filters = options
    // app.provide('$filters', options)
  }
}